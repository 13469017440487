<template>
  <div class="relative w-full" v-click-outside="clickOutside">
    <div
      class="
        p-1
        cursor-pointer
        select-none
        flex
        justify-between
        items-center
        w-full
      "
      @click="toggleOptions"
    >
      <input
        type="text"
        :value="selectedOption ? selectedOption.label : placeholder"
        readonly
        class="outline-none w-full"
      />

      <Icon
        name="chevron-down"
        class="text-gray transition-transform w-4"
        :class="{
          'transform-gpu rotate-180': showOptions,
        }"
      />
    </div>
    <ul v-if="showOptions" class="options w-full">
      <li
        v-for="option in options"
        :key="option.id"
        :class="{ selected: option.id === modelValue }"
        @click="selectOption(option)"
      >
        <span> {{ option.label }}</span>
      </li>
    </ul>
  </div>
</template>

<script>
import Icon from '@/components/Icon'

export default {
  components: {
    Icon,
  },
  props: {
    options: {
      type: Array,
      required: true,
    },
    placeholder: {
      type: String,
      default: 'Select an option',
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
  },
  data() {
    return {
      showOptions: false,
    }
  },
  computed: {
    selectedOption() {
      return this.options.find(option => option.id === this.modelValue)
    },
  },
  methods: {
    toggleOptions() {
      this.showOptions = !this.showOptions
    },
    selectOption(option) {
      this.$emit('update:modelValue', option.id)
      this.showOptions = false
    },
    clickOutside() {
      this.showOptions = false
    },
  },
}
</script>

<style scoped>
.options {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%;
  border: 1px solid #ccc;
  border-top: none;
  max-height: 200px;
  overflow-y: auto;
  background: white;
  z-index: 1000;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.1);
  padding: 0;
  margin: 0;
  list-style: none;
}

.options li {
  padding: 10px;
  cursor: pointer;
}

.options li:hover,
.options li.selected {
  background: #f0f0f0;
}

.p-1 {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>
