enum EAssetColor {
    HOT = '#76dd92',
    RHS = '#61d79b',
    DWH = '#4bd2a4',
    OFF = '#35ccad',
    RESI = '#26beb8',
    RWB1 = '#23b4b3',
    RWB2 = '#209aaf',
    RSM = '#1e8faa',
    HEC1 = '#1b85a5',
    HEC2 = '#197b9f',
}


enum EQualityColor {
    Low = '#76dd92',
    Mid = '#26beb8',
    High = '#1b9893',
}

enum ECountryColors {
    AF = "#FF0000", // Afghanistan - Red
    AL = "#00FF00", // Albania - Green
    DZ = "#0000FF", // Algeria - Blue
    AS = "#FFFF00", // American Samoa - Yellow
    AD = "#FF00FF", // Andorra - Magenta
    AO = "#00FFFF", // Angola - Cyan
    AG = "#FFA500", // Antigua and Barbuda - Orange
    AR = "#800080", // Argentina - Purple
    AM = "#FFC0CB", // Armenia - Pink
    AUS = "#808080", // Australia - Gray
    AT = "#FF6347", // Austria - Tomato
    AZ = "#008000", // Azerbaijan - Dark Green
    BS = "#000080", // Bahamas - Navy
    BH = "#808000", // Bahrain - Olive
    BD = "#008080", // Bangladesh - Teal
    BB = "#C0C0C0", // Barbados - Silver
    BY = "#FF4500", // Belarus - OrangeRed
    BE = "#8A2BE2", // Belgium - BlueViolet
    BZ = "#DAA520", // Belize - GoldenRod
    BJ = "#B22222", // Benin - FireBrick
    BT = "#7FFF00", // Bhutan - Chartreuse
    BO = "#4682B4", // Bolivia - SteelBlue
    BA = "#D2691E", // Bosnia and Herzegovina - Chocolate
    BW = "#9ACD32", // Botswana - YellowGreen
    BR = "#00FF7F", // Brazil - SpringGreen
    BN = "#ADFF2F", // Brunei - GreenYellow
    BG = "#FF69B4", // Bulgaria - HotPink
    BF = "#CD5C5C", // Burkina Faso - IndianRed
    BI = "#4B0082", // Burundi - Indigo
    CV = "#F0E68C", // Cabo Verde - Khaki
    KH = "#E6E6FA", // Cambodia - Lavender
    CM = "#7FFFD4", // Cameroon - Aquamarine
    CA = "#5F9EA0", // Canada - CadetBlue
    KY = "#D3D3D3", // Cayman Islands - LightGray
    CF = "#A52A2A", // Central African Republic - Brown
    TD = "#D8BFD8", // Chad - Thistle
    CL = "#6495ED", // Chile - CornflowerBlue
    CN = "#FF1493", // China - DeepPink
    CO = "#00BFFF", // Colombia - DeepSkyBlue
    KM = "#BDB76B", // Comoros - DarkKhaki
    CG = "#DC143C", // Congo (Brazzaville) - Crimson
    CD = "#00CED1", // Congo (Kinshasa) - DarkTurquoise
    CR = "#9400D3", // Costa Rica - DarkViolet
    CI = "#FF8C00", // Côte d’Ivoire - DarkOrange
    HR = "#8B0000", // Croatia - DarkRed
    CU = "#8B008B", // Cuba - DarkMagenta
    CY = "#006400", // Cyprus - DarkGreen
    CZ = "#1E90FF", // Czechia - DodgerBlue
    DK = "#FFFAF0", // Denmark - FloralWhite
    DJ = "#228B22", // Djibouti - ForestGreen
    DM = "#FFD700", // Dominica - Gold
    DO = "#DA70D6", // Dominican Republic - Orchid
    EC = "#EEE8AA", // Ecuador - PaleGoldenRod
    EG = "#98FB98", // Egypt - PaleGreen
    SV = "#AFEEEE", // El Salvador - PaleTurquoise
    GQ = "#DB7093", // Equatorial Guinea - PaleVioletRed
    ER = "#FFEFD5", // Eritrea - PapayaWhip
    EE = "#FFDAB9", // Estonia - PeachPuff
    SZ = "#CD853F", // Eswatini - Peru
    ET = "#FFC0CB", // Ethiopia - Pink
    FJ = "#DDA0DD", // Fiji - Plum
    FI = "#FF6347", //  Finland- Tomato
    FR = "#B0E0E6", //  France- PowderBlue
    GA = "#4169E1", // Gabon - RoyalBlue
    GM = "#8B4513", // Gambia - SaddleBrown
    GE = "#FA8072", // Georgia - Salmon
    DE = "#FFD700", // Germany - Gold
    GH = "#2E8B57", // Ghana - SeaGreen
    GR = "#FFF5EE", // Greece - SeaShell
    GD = "#A0522D", // Grenada - Sienna
    GT = "#C0C0C0", // Guatemala - Silver
    GN = "#87CEEB", // Guinea - SkyBlue
    GW = "#6A5ACD", // Guinea-Bissau - SlateBlue
    GY = "#708090", // Guyana - SlateGray
    HT = "#FFFAFA", // Haiti - Snow
    HN = "#00FF7F", // Honduras - SpringGreen
    HU = "#4682B4", // Hungary - SteelBlue
    IS = "#D2B48C", // Iceland - Tan
    IN = "#008080", // India - Teal
    ID = "#D8BFD8", // Indonesia - Thistle
    IR = "#FF6347", // Iran - Tomato
    IQ = "#40E0D0", // Iraq - Turquoise
    IE = "#EE82EE", // Ireland - Violet
    IL = "#F5DEB3", // Israel - Wheat
    IT = "#F5F5F5", // Italy - WhiteSmoke
    JM = "#9ACD32", // Jamaica - YellowGreen
    JP = "#ADD8E6", // Japan - LightBlue
    JO = "#90EE90", // Jordan - LightGreen
    KZ = "#FFB6C1", // Kazakhstan - LightPink
    KE = "#FFA07A", // Kenya - LightSalmon
    KI = "#20B2AA", // Kiribati - LightSeaGreen
    KW = "#87CEFA", // Kuwait - LightSkyBlue
    KG = "#778899", // Kyrgyzstan - LightSlateGray
    LA = "#B0C4DE", // Laos - LightSteelBlue
    LV = "#FFFFE0", // Latvia - LightYellow
    LB = "#00FF00", // Lebanon - Lime
    LS = "#32CD32", // Lesotho - LimeGreen
    LR = "#FAFAD2", // Liberia - LightGoldenRodYellow
    LY = "#F08080", // Libya - LightCoral
    LI = "#E0FFFF", // Liechtenstein - LightCyan
    LT = "#FF00FF", // Lithuania - Magenta
    LU = "#800000", // Luxembourg - Maroon
    MG = "#66CDAA", // Madagascar - MediumAquaMarine
    MW = "#0000CD", // Malawi - MediumBlue
    MY = "#BA55D3", // Malaysia - MediumOrchid
    MV = "#9370DB", // Maldives - MediumPurple
    ML = "#3CB371", // Mali - MediumSeaGreen
    MT = "#7B68EE", // Malta - MediumSlateBlue
    MH = "#00FA9A", // Marshall Islands - MediumSpringGreen
    MR = "#48D1CC", // Mauritania - MediumTurquoise
    MU = "#C71585", // Mauritius - MediumVioletRed
    MX = "#191970", // Mexico - MidnightBlue
    FM = "#FFE4B5", // Micronesia - Moccasin
    MD = "#FFDEAD", // Moldova - NavajoWhite
    MC = "#000080", // Monaco - Navy
    MN = "#FDF5E6", // Mongolia - OldLace
    ME = "#6B8E23", // Montenegro - OliveDrab
    MA = "#FF4500", // Morocco - OrangeRed
    MZ = "#DA70D6", // Mozambique - Orchid
    MM = "#EEE8AA", // Myanmar - PaleGoldenRod
    NA = "#98FB98", // Namibia - PaleGreen
    NR = "#AFEEEE", // Nauru - PaleTurquoise
    NP = "#FF6347", // Nepal - Tomato
    NL = "#DB7093", // Netherlands - PaleVioletRed
    NZ = "#FFDAB9", // New Zealand - PeachPuff
    NI = "#CD853F", // Nicaragua - Peru
    NE = "#FFC0CB", // Niger - Pink
    NG = "#DDA0DD", // Nigeria - Plum
    KP = "#B0E0E6", // North Korea - PowderBlue
    MK = "#BC8F8F", // North Macedonia - RosyBrown
    NO = "#4169E1", // Norway - RoyalBlue
    OM = "#8B4513", // Oman - SaddleBrown
    PK = "#FA8072", // Pakistan - Salmon
    PW = "#F4A460", // Palau - SandyBrown
    PA = "#2E8B57", // Panama - SeaGreen
    PG = "#FFF5EE", // Papua New Guinea - SeaShell
    PY = "#A0522D", // Paraguay - Sienna
    PE = "#C0C0C0", // Peru - Silver
    PH = "#87CEEB", // Philippines - SkyBlue
    PL = "#6A5ACD", // Poland - SlateBlue
    PT = "#708090", // Portugal - SlateGray
    QA = "#FFFAFA", // Qatar - Snow
    RO = "#00FF7F", // Romania - SpringGreen
    RU = "#4682B4", // Russia - SteelBlue
    RW = "#D2B48C", // Rwanda - Tan
    KN = "#008080", // Saint Kitts and Nevis - Teal
    LC = "#D8BFD8", // Saint Lucia - Thistle
    VC = "#FF6347", // Saint Vincent and the Grenadines - Tomato
    WS = "#40E0D0", // Samoa - Turquoise
    SM = "#EE82EE", // San Marino - Violet
    ST = "#F5DEB3", // São Tomé and Príncipe - Wheat
    SA = "#F5F5F5", // Saudi Arabia - WhiteSmoke
    SN = "#9ACD32", // Senegal - YellowGreen
    RS = "#ADD8E6", // Serbia - LightBlue
    SC = "#90EE90", // Seychelles - LightGreen
    SL = "#FFB6C1", // Sierra Leone - LightPink
    SG = "#FFA07A", // Singapore - LightSalmon
    SK = "#20B2AA", // Slovakia - LightSeaGreen
    SI = "#87CEFA", // Slovenia - LightSkyBlue
    SB = "#778899", // Solomon Islands - LightSlateGray
    SO = "#B0C4DE", // Somalia - LightSteelBlue
    ZA = "#FFFFE0", // South Africa - LightYellow
    KR = "#00FF00", // South Korea - Lime
    SS = "#32CD32", // South Sudan - LimeGreen
    ES = "#FAFAD2", // Spain - LightGoldenRodYellow
    LK = "#F08080", // Sri Lanka - LightCoral
    SD = "#E0FFFF", // Sudan - LightCyan
    SR = "#FF00FF", // Suriname - Magenta
    SE = "#800000", // Sweden - Maroon
    CH = "#66CDAA", // Switzerland - MediumAquaMarine
    SY = "#0000CD", // Syria - MediumBlue
    TW = "#BA55D3", // Taiwan - MediumOrchid
    TJ = "#9370DB", // Tajikistan - MediumPurple
    TZ = "#3CB371", // Tanzania - MediumSeaGreen
    TH = "#7B68EE", // Thailand - MediumSlateBlue
    TL = "#00FA9A", // Timor-Leste - MediumSpringGreen
    TG = "#48D1CC", // Togo - MediumTurquoise
    TO = "#C71585", // Tonga - MediumVioletRed
    TT = "#191970", // Trinidad and Tobago - MidnightBlue
    TN = "#FFE4B5", // Tunisia - Moccasin
    TR = "#FFDEAD", // Turkey - NavajoWhite
    TM = "#000080", // Turkmenistan - Navy
    TV = "#FDF5E6", // Tuvalu - OldLace
    UG = "#6B8E23", // Uganda - OliveDrab
    UA = "#FF4500", // Ukraine - OrangeRed
    AE = "#DA70D6", // United Arab Emirates - Orchid
    GB = "#EEE8AA", // United Kingdom - PaleGoldenRod
    USA = "#98FB98", // United States - PaleGreen
    UY = "#AFEEEE", // Uruguay - PaleTurquoise
    UZ = "#DB7093", // Uzbekistan - PaleVioletRed
    VU = "#FFEFD5", // Vanuatu - PapayaWhip
    VE = "#FFDAB9", // Venezuela - PeachPuff
    VN = "#CD853F", // Vietnam - Peru
    YE = "#FFC0CB", // Yemen - Pink
    ZM = "#DDA0DD", // Zambia - Plum
    ZW = "#B0E0E6"  // Zimbabwe - PowderBlue
}

enum EClusterColor {
    CLU1 = '#76dd92',
    CLU2 = '#23b4b3',
    CLU3 = '#1e8faa',
    CLU4 = '#35ccad',
    CLU5 = '#26beb8',
}

export { EAssetColor, EQualityColor, ECountryColors, EClusterColor }
