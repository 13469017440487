import axios, { Canceler, AxiosResponse } from 'axios'
import axiosClient from './http'
import {
  IObject,
  IObjectCreate,
  IObjectEdit,
  IAssetClass,
  IObjectQuestionnaireInfo,
  ICountry,
  IAssetAccessDecisionResponse,
  IReviewProcess,
  ICertificate,
} from '@/models/object'
import { EAssetState, EExportFormat } from '@/enums'
import httpAxiosClient from './http'
import { IAssetData, IConsumptionValue } from '@/models/benchmark'

type Id = string | number

const CancelToken = axios.CancelToken
let cancelRequest: Canceler

export default {
  async getList(assetState?: EAssetState): Promise<Array<IObject>> {
    const params = new URLSearchParams()
    if (assetState !== undefined && assetState !== null) {
      params.append('assetState', `${assetState}`)
    }
    try {
      const res = await axiosClient.get(`asset?${params}`, {
        cancelToken: new CancelToken(c => {
          cancelRequest = c
        }),
      })
      return res.data
    } catch (error) {
      if (axios.isCancel(error)) {
        return []
      } else {
        throw error
      }
    }
  },
  async getQuestionnairesInfos(): Promise<Partial<IObjectQuestionnaireInfo>> {
    const res = await axiosClient.get('asset/questionnaires/info')
    return res.data
  },
  async getById(id: string, isBuyerAsset: boolean): Promise<IObject> {
    const res = await axiosClient.get(`asset/${id}`, { params: { isBuyerAsset } })
    return res.data
  },
  async create(object: IObjectCreate): Promise<IObject> {
    const res = await axiosClient.post('asset', object)
    return res.data
  },
  async edit(obj: IObjectEdit): Promise<IObject> {
    const res = await axiosClient.put('asset', obj)
    return res.data
  },
  async getConsumptionValues(assetId: number, questionnaireId: number): Promise<IConsumptionValue[]> {
    const res = await axiosClient.get(`asset/${assetId}/questionnaire/${questionnaireId}/consumptionValues`)
    return res.data
  },
  async getAssetBenchmarkData(assetId: number): Promise<IAssetData> {
    const res = await axiosClient.get(`asset/data/${assetId}`)
    return res.data
  },
  delete(id: string): Promise<void> {
    return axiosClient.delete(`asset/${id}`)
  },

  async getAssetClasses(): Promise<Array<IAssetClass>> {
    const res = await axiosClient.get('asset/assetType')
    return res.data
  },

  async getCountries(): Promise<Array<ICountry>> {
    const res = await axiosClient.get('asset/countries')
    return res.data
  },

  async assignTester(assetId: number, testerId: number): Promise<void> {
    const params = new URLSearchParams({
      assetId: `${assetId}`,
      testerId: `${testerId}`,
    })
    const res = await axiosClient.put(`asset/tester?${params}`)
    return res.data
  },

  async requestAccess(assetId: number, language: string): Promise<void> {
    const res = await axiosClient.post(
      `asset/${assetId}/requestAssetAccess`,
      null,
      { headers: { 'Accept-Language': language } }
    )
    return res.data
  },

  async decideAccess(
    id: number,
    token: string,
    decision: boolean
  ): Promise<IAssetAccessDecisionResponse> {
    const res = await axiosClient.post(
      `asset/requestAssetAccess/${id}?token=${token}&decision=${decision}`,
      null
    )
    return res.data
  },

  async getConfig(): Promise<IReviewProcess> {
    const res = await axiosClient.get(`asset/config`)
    return res.data
  },

  async getCertificate(assetId: number): Promise<ICertificate> {
    const res = await axiosClient.get(`asset/certificate`, {
      params: { id: assetId },
    })
    return res.data
  },
  async exportObject(
    assetId: Id,
    language: string,
    exportFormat: EExportFormat,
  ): Promise<void> {
    const link = document.createElement('a')
    const params = new URLSearchParams({
      exportFormat: `${exportFormat}`,
      language,
    })
    const exportUrl = `export/questionnaire/${assetId}/latest?${params}`
    const EXPORT_FORMAT_TYPE_MAPPING = {
      0: 'application/json',
      1: 'text/csv',
      2: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
    }
    const response: AxiosResponse<Blob> = await httpAxiosClient.get(exportUrl, {
      responseType: 'blob',
    })
    const type = EXPORT_FORMAT_TYPE_MAPPING[exportFormat]
    const blob = new Blob([response.data], { type })

    link.setAttribute('href', URL.createObjectURL(blob))
    link.setAttribute('download', '')

    document.body.appendChild(link)
    link.click()
    document.body.removeChild(link)
  },

  cancelRequest(): void {
    cancelRequest()
  },
}
