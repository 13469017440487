export default {
  "general": {
    "logout": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausloggen"])},
    "loading": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lade"])},
    "cancel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abbrechen"])},
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "saveAll": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle speichern"])},
    "release": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigeben"])},
    "send": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Absenden"])},
    "create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellen"])},
    "delete": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "add": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hinzufügen"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "done": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fertig"])},
    "leave": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlassen"])},
    "check": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfen"])},
    "evaluate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Evaluierung"])},
    "okay": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Okay"])},
    "confirm": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestätigen"])},
    "language": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache"])},
    "language-de": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutsch"])},
    "language-en": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Englisch"])},
    "assetClass-OFF": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Büro"])},
    "assetClass-HOT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hotel"])},
    "assetClass-RHS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Handel High Street"])},
    "assetClass-RSM": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einkaufszentren"])},
    "assetClass-RWB1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nahversorger"])},
    "assetClass-RWB2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fachmarktzentren"])},
    "assetClass-DWH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logistik"])},
    "assetClass-HEC1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflegeimmobilien"])},
    "assetClass-HEC2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sozialimmobilien"])},
    "assetClass-LEI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterkunft und Freizeit"])},
    "assetClass-MIX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gemischt"])},
    "assetClass-RESI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wohnen"])},
    "assetClass-pilot": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pilot"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine"])},
    "years": (ctx) => {const { normalize: _normalize, plural: _plural } = ctx;return _plural([_normalize(["Jahr"]), _normalize(["Jahre"])])},
    "assign": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zuweisen"])},
    "country-AT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Österreich"])},
    "country-AUS": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Australien"])},
    "country-BE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belgien"])},
    "country-BG": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bulgarien"])},
    "country-BRA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brasilien"])},
    "country-HR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kroatien"])},
    "country-CAN": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kanada"])},
    "country-CY": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zypern"])},
    "country-CHI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["China"])},
    "country-CH": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweiz"])},
    "country-CZ": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tschechische Republik"])},
    "country-DK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dänemark"])},
    "country-EE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estland"])},
    "country-FI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finnland"])},
    "country-FR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frankreich"])},
    "country-DE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Deutschland"])},
    "country-EL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Griechenland"])},
    "country-HK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hongkong"])},
    "country-HU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungarn"])},
    "country-IE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Irland"])},
    "country-IND": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Indien"])},
    "country-IT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Italien"])},
    "country-KOR": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Südkorea"])},
    "country-JAP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Japan"])},
    "country-LV": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lettland"])},
    "country-LT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litauen"])},
    "country-LU": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luxemburg"])},
    "country-MAL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malaysia"])},
    "country-MEX": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mexiko"])},
    "country-MT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Malta"])},
    "country-NL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Niederlande"])},
    "country-NO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Norwegen"])},
    "country-NZL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuseeland"])},
    "country-PHI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Philippinen"])},
    "country-PL": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Polen"])},
    "country-PT": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portugal"])},
    "country-RO": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rumänien"])},
    "country-SGP": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Singapur"])},
    "country-SK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slowakei"])},
    "country-SI": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Slowenien"])},
    "country-ES": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spanien"])},
    "country-SE": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schweden"])},
    "country-UK": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinigtes Königreich"])},
    "country-USA": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vereinigte Staaten"])},
    "country-other": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land nicht in der Liste"])},
    "screenTooSmall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte verwenden sie einen breiteren Bildschirm (mindestens 1024px)"])},
    "update": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktualisieren"])},
    "deletedUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gelöschter Benutzer"])},
    "accept": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Akzeptieren"])},
    "decline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "close": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Schließen"])},
    "download-label": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Download Label"])},
    "search-options": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Suchoptionen"])},
    "more-class": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Assetklasse hinzufügen"])}
  },
  "roles": {
    "accountAdministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountadministrator"])},
    "billingManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abrechnungsmanager"])},
    "evaluator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluator"])},
    "scoringModelManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Scoringmodellmanager"])},
    "objectAdministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektadministrator"])},
    "accountManager": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Accountmanager"])},
    "objectDataCollector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektdatenerfasser"])},
    "objectAnalyst": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektanalyst"])},
    "internalTester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Interner Prüfer"])},
    "externalAccreditedTester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Verifier"])},
    "prospectiveBuyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kreditnehmer"])}
  },
  "pages": {
    "home": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startseite"])},
    "score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen Management"])},
    "scoreAdmin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adminbereich"])},
    "editQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen"])},
    "questionnairesActive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktive Fragebögen"])},
    "questionnairesArchived": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Archivierte Fragebögen"])},
    "metadata": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Big Picture"])},
    "accountManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account Management"])},
    "userManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "organisationManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisationen"])},
    "assetManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt Management"])},
    "objectManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekte"])},
    "objectGroupManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppen"])},
    "accessRequest": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriffsanfrage"])},
    "editObjectInfo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisdaten"])},
    "questionnaireResultsOverview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogenübersicht"])},
    "cluster": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cluster ", _interpolate(_named("num"))])},
    "jobManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auftragsverwaltung"])},
    "certificate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zertifikat"])},
    "jobObjectManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekte"])},
    "jobObjectGroupManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppen"])},
    "faq": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FAQ"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "imprint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Impressum"])},
    "dataProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datenschutz"])},
    "settings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einstellungen"])},
    "checkAsset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt Prüfen"])},
    "dashboard": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "export": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Export"])},
    "objectExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekte"])},
    "objectGroupExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppen"])},
    "customerDataExport": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kundendaten"])},
    "analysisAndBenchmark": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Analyse und Benchmark"])},
    "benchmarkObjectGroupManagement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benchmark Objektgruppe Management"])},
    "benchmarkObjectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benchmark Objektgruppe"])}
  },
  "input": {
    "requiredFields": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pflichtfelder"])},
    "pleaseChoose": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte auswählen"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzername"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort / Password"])},
    "passwordRepeat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort wiederholen / Repeat password"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "firstName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorname"])},
    "lastName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name"])},
    "organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "organisationName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Organisation"])},
    "companyPosition": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Position im Unternehmen"])},
    "phoneNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Telefon"])},
    "mobileNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mobiltelefon"])},
    "city": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stadt"])},
    "street": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße"])},
    "streetNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nummer"])},
    "zip": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ"])},
    "country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "contactName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Ansprechpartners"])},
    "objectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Objekts / des Projektes"])},
    "objectGroupName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Objektgruppe"])},
    "objectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppe"])},
    "assetClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektklasse"])},
    "assetClass-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sofern eine Nutzungsart mehr als 85% der Hauptnutzung ausmacht, können die weiteren Nutzungsarten vernachlässigt werden. Die Hauptnutzungsart ist dann mit 100% einzutragen.\n\nAndernfalls sind die jeweiligen Nutzungsarten und deren prozentualer Anteil an der Fläche einzutragen."])},
    "constructionYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr / Datum der Baufertigstellung"])},
    "constructionYear-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie das Baujahr an."])},
    "renovationYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Letzte Renovierung"])},
    "renovationYear-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie das Jahr der letzten Sanierung an. Bei General- bzw. Kernsanierungen muss der Fragenkatalog \"Neubau/ Projektentwicklung\" angewendet werden"])},
    "featureQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Qualität der Ausstattung"])},
    "featureQuality-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie ist die Ausstattungsqualität des Gebäudes gemäß der Definition von NEO?"])},
    "assetTotalArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Grundfläche in m²"])},
    "assetTotalArea-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie in m² die Bruttogeschossfläche an."])},
    "assetUsableArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mietfläche in m²"])},
    "assetUsableArea-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie die NUF nach DIN 277 in m2 an.\n(Info: Unter der Nutzungsfläche (kurz NUF) nach DIN 277, bis 2016 Nutzfläche (NF), eines Gebäudes versteht man den Anteil der Geschossfläche, der entsprechend der Zweckbestimmung des Bauwerks genutzt wird. Nicht zur Nutzfläche gehören Verkehrsflächen (VF) wie Eingangsbereiche, Treppenräume, Aufzüge und Flure, Technikflächen (TF) (Heizungsraum, Maschinenräume, technische Betriebsräume) und natürlich auch nicht die Konstruktions-Grundflächen (KGF) des Gebäudes wie Wände und Stützen.)"])},
    "numTenants": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Mieter"])},
    "numTenants-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wie viele Mietparteien sind in dem Objekt?"])},
    "historicProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalschutz"])},
    "historicProtection-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ist das Gebäude ein Baudenkmal, d.h. ein nach Landesrecht geschütztes Gebäude oder eine nach Landesrecht geschützte Gebäudemehrheit?"])},
    "questionnaireName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Fragebogens"])},
    "tester": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Verifier"])},
    "solutionPartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Solution partner"])},
    "testAccount": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Testaccount"])},
    "financePartner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance partner"])},
    "buyer": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Käufer"])},
    "ipms2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IPMS2"])},
    "ipms2-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INTERNATIONAL PROPERTY MEASUREMENT STANDARDS\nFläche eines Gebäudes bis zur maßgeblichen inneren Raumbegrenzung, unterteilt in Komponenten"])},
    "scoreType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score-Typ"])},
    "conditionsAndEnvironmentGoals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zustand & Umweltziel (Taxonomie)"])},
    "conditionsAndEnvironmentGoals-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektentwicklung: bis zur Fertigstellung/ Übergabe/ Inbetriebnahme(aktuell nur möglich für die Assetklassen Büro/ Hotel/ Logistik/ Handel (high street))\nNeubau: Bestandsgebäude, deren Fertigstellung weniger als 3 Jahre zurückliegt\nSanierung: Die energ. Sanierung, Modernisierung oder Revitalisierung darf maximal 3 Jahre zurückliegen"])},
    "conditionedArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Konditionierte Fläche"])},
    "conditionedArea-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie in m2 die konditionierte (beheizte) Fläche an. Diese wird auch im Energieausweis verwendet. (EPC-Rating) "])},
    "projectCharacteristics": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektcharakteristika"])},
    "determinationStartDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Startdatum des Projekts"])},
    "determinationStartDate-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Festlegung Startdatum: LP 1 (Grundlagenermittlung)Ankauf (Grundstückskauf (Closing, Übergang Nutzen/Lasten))"])},
    "acceptanceDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Abnahme"])},
    "handoverDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Übergabe"])},
    "handoverDate-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Mietern: an alle Mieter / Investor, etc."])},
    "projectStatus": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektstatus (HOAI)"])},
    "none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["keine Angabe"])},
    "percentage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prozentsatz"])}
  },
  "error": {
    "request": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bei Ihrer Anfrage ist ein Fehler aufgetreten"])},
    "required": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie dieses Feld aus"])},
    "requiredRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie mindestens eine Nutzerrolle"])},
    "email": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gültige E-Mail-Adresse"])},
    "identicalPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwörter stimmen nicht überein"])},
    "numeric": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte geben Sie ein Zahl ein"])},
    "minLengthArray": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Bitte geben Sie mindestens ", _interpolate(_named("min")), " Optionen an"])},
    "general": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte überprüfen Sie Ihre Eingabe"])},
    "userName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine gültige Benutzername"])},
    "minLengthString": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dieses Feld sollte mindestens ", _interpolate(_named("minNumber")), " Zeichen lang sein"])},
    "passwordProperFormat": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort muss aus Buchstaben und Zahlen bestehen"])}
  },
  "table": {
    "resultsPerPage": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ergebnisse pro Seite"])},
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Einträge vorhanden"])},
    "loadError": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Laden der Daten ist ein Fehler aufgetreten"])}
  },
  "login": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Willkommen / Welcome"])},
    "plsLogIn": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Loggen Sie sich hier ein / Login here"])},
    "forgotPassword": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen? / Forgot password?"])},
    "submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "badCredentials": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["E-Mail und Passwort stimmen nicht überein / Mail or password are not correct."])},
    "forgotPassword-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort vergessen? / Forgot password?"])},
    "forgotPassword-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geben Sie eine bekannte Benutzername ein und wir senden Ihnen eine E-Mail zum Zurücksetzen des Passworts zu. / Enter a known username and we will send you a password reset email."])},
    "forgotPassword-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eine E-Mail mit Link zum Zurücksetzen des Passworts wurde an die von Ihnen gesendet.  / An email with a link to reset the password has been sent to your email address."])},
    "setPassword-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort festlegen / Set password"])},
    "setPassword-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Legen Sie das Passwort für Ihren Account ", _interpolate(_named("username")), " fest. / Set Password for your account"])},
    "setPassword-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Passwort sollte min. 8 Zeichen haben und aus Buchstaben und Zahlen bestehen / The password should have at least 8 characters and contain letters and numbers"])},
    "setPassword-finish": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort setzen / Set Password"])},
    "setPassword-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passwort erfolgreich festgelegt / Password successfully set"])},
    "setPassword-toLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "checkYourEmailBox2fa": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es wurde eine E-Mail mit dem Link zum Abschließen des Zwei-Faktor-Authentifizierungsprozesses an Ihre E-Mail-Adresse gesendet. / An email with a link to complete the two-factor authentication process has been sent to the address you provided."])},
    "backToLogin": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zurück zur Loginseite / Go back to login page"])},
    "invalidTokenOrEmail": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Link ist nicht korrekt. Er ist möglicherweise abgelaufen. / The link is not correct. It may have expired."])}
  },
  "accountManagement": {
    "fullRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie sind Accountmanager bei ECORE Score, hier können Sie alle Nutzeraccounts und Organisationen verwalten"])},
    "userAdminRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie alle Nutzeraccounts Ihrer Organisation verwalten"])},
    "createUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer erstellen"])},
    "createOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation erstellen"])},
    "searchUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account suchen ..."])},
    "searchOrganisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation suchen ..."])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
    "locked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesperrt"])},
    "pending": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anstehend"])},
    "users-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer"])},
    "users-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name, Vorname"])},
    "users-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "users-role": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rolle"])},
    "users-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "users-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "users-activate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account entsperren"])},
    "users-activate-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie den Account von ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName")), " entsperren? Bestehende informationen, die diesem Account zugewiesen sind, werden nicht beeinträchtigt."])},
    "users-activate-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie den Account entsperren, ermöglichen Sie ihm die Möglichkeit das ECORE Portal zu nutzen."])},
    "users-activate-description3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Kontaktpersonen ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName")), " wird per E-Mail über die Entsperrung informiert."])},
    "users-activate-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entsperren"])},
    "users-deactivate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account sperren"])},
    "users-deactivate-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie den Account von ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName")), " sperren?"])},
    "users-deactivate-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestehende Informationen, die diesem Account zugewiesen sind, werden nicht beeinträchtigt. Wenn Sie den Nutzer sperren, entziehen Sie ihm die Möglichkeit das ECORE-Portal zu nutzen"])},
    "users-deactivate-description3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Kontaktperson ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName")), " wird per E-Mail über die Sperrung informiert."])},
    "users-deactivate-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sperren"])},
    "users-archive-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Account löschen"])},
    "users-archive-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie den Account von ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName")), " wirklich löschen? "])},
    "users-archive-description2": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Alle Informationen, die diesem Account zugewiesen sind, werden gelöscht. Die Kontaktperson ", _interpolate(_named("firstName")), " ", _interpolate(_named("lastName")), " wird per E-Mail über die Löschung informiert."])},
    "users-archive-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schritt kann nicht rückgängig gemacht werden."])},
    "users-archive-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "users-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Benutzer erstellen"])},
    "users-edit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzerinformationen bearbeiten"])},
    "users-edit-roles": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Berechtigung"])},
    "users-edit-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzerdaten"])},
    "users-edit-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe"])},
    "users-edit-access-objectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe pro Objektgruppe"])},
    "users-edit-access-objectGroup-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Freigabe für alle Objektgruppen keine Auswahl treffen."])},
    "users-edit-access-object": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Freigabe pro Objekt"])},
    "users-edit-access-object-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Freigabe für alle Objekte keine Auswahl treffen."])},
    "users-edit-externalRole": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Extern"])},
    "users-edit-mainContactPerson": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hauptansprechpartner"])},
    "organisations-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisationen"])},
    "organisations-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Organisation"])},
    "organisations-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunden-ID"])},
    "organisations-address": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adresse"])},
    "organisations-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "organisations-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "organisations-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Organisation erstellen"])},
    "organisations-edit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation bearbeiten"])},
    "organisations-edit-account": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation Account"])},
    "organisations-edit-differingBilling": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Andere Rechnungsaddresse"])},
    "organisations-edit-billingAddress": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rechnungsaddresse"])},
    "organisations-edit-contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner"])},
    "organisations-editContacts-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ansprechpartner editieren"])},
    "organisations-editContacts-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontaktdaten"])},
    "organisations-editContacts-create": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neu erstellen"])},
    "organisations-editContacts-newName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuer Ansprechpartner"])},
    "organisations-activate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation entsperren"])},
    "organisations-activate-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Organisation ", _interpolate(_named("organisation")), " entsperren? Bestehende informationen, die diesem Account zugewiesen sind, werden nicht beeinträchtigt. "])},
    "organisations-activate-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie das Konto entsperren, erlauben Sie die Möglichkeit, im ECORE-Portal als Organisation ausgewählt zu werden. "])},
    "organisations-activate-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kontaktpersonen wird per E-Mail über die Entsperrung informiert."])},
    "organisations-activate-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entsperren"])},
    "organisations-deactivate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation sperren"])},
    "organisations-deactivate-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Organisation ", _interpolate(_named("organisation")), " sperren?"])},
    "organisations-deactivate-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestehende Informationen, die diesem Account zugewiesen sind, werden nicht beeinträchtigt."])},
    "organisations-deactivate-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kontaktpersonen wird per E-Mail über die Sperrung informiert."])},
    "organisations-deactivate-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sperren"])},
    "organisations-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation löschen"])},
    "organisations-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Organisation ", _interpolate(_named("name")), " wirklich löschen? "])},
    "organisations-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Informationen, die diese Organisation zugewiesen sind, werden gelöscht. Die Kontaktperson wird per E-Mail über die Löschung informiert."])},
    "organisations-delete-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schritt kann nicht rückgängig gemacht werden."])},
    "organisations-delete-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Löschen"])},
    "solutionPartner-abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SP"])},
    "organisations-edit-select-finance-partner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie die zugehörige Finanzpartner Organisation"])},
    "financePartner-abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["FP"])},
    "isBuyer-abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["B"])},
    "isTestAccount-abbreviation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["T"])}
  },
  "assetManagement": {
    "fullRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie alle Objekte und Objektgruppen verwalten"])},
    "jobManagement-fullRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Auf dieser Seite können Sie alle eingereichten Fragebögen einsehen."])},
    "createObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt erstellen"])},
    "createObjectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppe erstellen"])},
    "searchObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt suchen ..."])},
    "searchObjectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppe suchen ..."])},
    "all": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle"])},
    "open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
    "active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommen"])},
    "declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
    "verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachweise hochladen"])},
    "definitelyDeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definitiv abgelehnt"])},
    "objects-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekte"])},
    "objects-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektname"])},
    "objects-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt-ID"])},
    "objects-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "objects-createdAt": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt am"])},
    "objects-createdBy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erstellt von"])},
    "objects-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score"])},
    "objects-builtYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr"])},
    "objects-renovationYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanierungsjahr"])},
    "objects-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "objects-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "objects-status-open": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
    "objects-status-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "objects-status-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "objects-status-evaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Evaluation"])},
    "objects-status-closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommen"])},
    "objects-status-declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
    "objects-status-verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachweise hochladen"])},
    "objects-status-definitelyDeclined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Definitiv abgelehnt"])},
    "objects-checkedStatus-checked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Geprüft"])},
    "objects-checkedStatus-unchecked": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
    "objects-assigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Prüfung"])},
    "objects-unassigned": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Offen"])},
    "objects-testerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Verifier"])},
    "objects-objectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppe"])},
    "objects-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neues Objekt erstellen"])},
    "objects-edit-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektdaten"])},
    "objects-edit-details": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Details"])},
    "objects-edit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt bearbeiten"])},
    "objects-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt erfolgreich aktualisiert"])},
    "objects-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt löschen"])},
    "objects-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie das Objekt ", _interpolate(_named("object")), " wirklich löschen? "])},
    "objects-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle diesem Objekt zugewiesenen Informationen werden gelöscht."])},
    "objects-delete-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schritt kann nicht rückgängig gemacht werden."])},
    "objects-featureQuality-low": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einfach"])},
    "objects-featureQuality-mid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mittel"])},
    "objects-featureQuality-high": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hochwertig"])},
    "objects-historicProtection-noStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angabe"])},
    "objects-historicProtection-selectedAreas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur ausgewählte Bereiche"])},
    "objects-historicProtection-facade": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nur Fassade"])},
    "objects-historicProtection-facadeAndSelectedAreas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fassade und ausgewählte Bereiche"])},
    "objects-historicProtection-none": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nein"])},
    "objectGroups-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppen"])},
    "objectGroups-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Objektgruppe"])},
    "objectGroups-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppen-ID"])},
    "objectGroups-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation"])},
    "objectGroups-status": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Status"])},
    "objectGroups-testerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Verifier"])},
    "objectGroups-actions": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktionen"])},
    "objectGroups-objects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekte"])},
    "objectGroups-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neue Objektgruppe erstellen"])},
    "objectGroups-create-group": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppe"])},
    "objectGroups-edit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppe bearbeiten"])},
    "objectGroups-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppe löschen"])},
    "objectGroups-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Objektgruppe ", _interpolate(_named("group")), " wirklich löschen? "])},
    "objectGroups-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle dieser Objektgruppe zugewiesenen Informationen werden gelöscht."])},
    "objectGroups-delete-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schritt kann nicht rückgängig gemacht werden."])},
    "objectGroups-submit-for-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Prüfung"])},
    "object-assignTester-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer zuweisen"])},
    "objectgroups-status-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "objectgroups-status-review": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review"])},
    "objectgroups-status-reviewfailed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review fehlgeschlagen"])},
    "objectgroups-status-reviewpassed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Review bestanden"])},
    "objectgroups-status-closed": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommen"])},
    "object-assignTester-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welchen Prüfer möchten Sie zu diesem Fragebogen zuweisen?"])},
    "objectGroup-assignTester-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer zuweisen"])},
    "objectGroup-assignTester-description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Welchen Prüfer möchten Sie zu dieser Objektgruppe zuweisen?"])},
    "resultOverview-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtüberblick"])},
    "resultDetail-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Detailansicht"])},
    "startQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Fragebogen ausfüllen"])},
    "questionnaire-start-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Fragebogen ausfüllen"])},
    "questionnaire-start-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie einen neuen Fragebogen für das Objekt \"", _interpolate(_named("objectName")), "\" erstellen?"])},
    "questionnaire-lastChange": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Letzte Änderung am ", _interpolate(_named("date")), " von ", _interpolate(_named("user")), "."])},
    "access-request-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugriffsanfrage"])},
    "access-request-infotext": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn das Objekt bereits im ECORE Tool existiert können Sie hier die Objekt ID des gewünschten Objektes eintragen um eine Zugriffsanfrage an die zugehörige Organisation zu senden. Die Objekt ID können Sie durch eine direkte Absprache mit der Organisation erhalten."])},
    "access-request-objectid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt ID"])},
    "access-request-request-now": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jetzt anfrangen"])},
    "access-request-language-hint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Sprache, in der die Objekteigentümer benachrichtigt werden sollen."])},
    "approve-access-request-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben die Zugriffsanfrage der Organisation ", _interpolate(_named("organisationName")), " für das Objekt ", _interpolate(_named("objectName")), " angenommen."])},
    "decline-access-request-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sie haben die Zugriffsanfrage der Organisation ", _interpolate(_named("organisationName")), " für das Objekt ", _interpolate(_named("objectName")), " abgelehnt."])},
    "approve-access-request-warning-already-answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anfrage zum Zugriff auf dieses Objekt wurde bereits beantwortet."])},
    "approve-access-request-error-invalid-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Annahme des Zugriffs auf dieses Objekt ist fehlgeschlagen. Das Token im Link ist ungültig. "])},
    "approve-access-request-error-invalid-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Annahme des Zugriffs auf dieses Objekt ist fehlgeschlagen. Die Anfrage konnte nicht identifiziert werden."])},
    "approve-access-request-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Annahme des Zugriffs auf dieses Objekt ist fehlgeschlagen."])},
    "decline-access-request-warning-already-answered": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anfrage zum Zugriff auf dieses Objekt wurde bereits beantwortet."])},
    "decline-access-request-error-invalid-token": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Ablehnung des Zugriffs auf dieses Objekt ist fehlgeschlagen. Das Token im Link ist ungültig."])},
    "decline-access-request-error-invalid-id": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Ablehnung des Zugriffs auf dieses Objekt ist fehlgeschlagen. Die Anfrage konnte nicht identifiziert werden."])},
    "decline-access-request-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Ablehnung des Zugriffs auf dieses Objekt ist fehlgeschlagen."])},
    "expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelaufen"])},
    "objects-status-expired": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelaufen"])},
    "objects-CEG-StockAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand & Klimaschutz"])},
    "objects-CEG-StockAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand & Klimawandelanpassung"])},
    "objects-CEG-RenovationAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanierung & Klimaschutz"])},
    "objects-CEG-RenovationAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanierung & Klimawandelanpassung"])},
    "objects-CEG-NewConstructionAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neubau & Klimaschutz"])},
    "objects-CEG-NewConstructionAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neubau & Klimawandelanpassung"])},
    "objects-CEG-ProjectDevelopmentAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektentwicklung & Klimaschutz"])},
    "objects-CEG-ProjectDevelopmentAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektentwicklung & Klimawandelanpassung"])},
    "objects-CEG-NewConstructionAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neubau & Kreislaufwirtschaft"])},
    "objects-CEG-ProjectDevelopmentAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektentwicklung & Kreislaufwirtschaft"])},
    "objects-CEG-RenovationAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanierung & Kreislaufwirtschaft"])},
    "objects-PC-GreenfieldDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Greenfield-Development (Neubau)"])},
    "objects-PC-DemolitionAndNewConstruction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abriss und Neubau (Urbanes Umfeld (bereits def. Bauland) (Neubau)"])},
    "objects-PC-BrownfieldDevelopment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Brownfield-Development (Kontaminierte Flächen, Industriebrache) (Neubau)"])},
    "objects-PC-Redensification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Nachverdichtung (z.B. Aufstockung, Flächenverdichtung) (Sanierung)"])},
    "objects-PC-RedevelopmentOfExistingBuildings": (ctx) => {const { normalize: _normalize } = ctx;return _normalize([" Bestandssanierung  (Sanierung)"])},
    "objects-PS-BasicEvaluation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP1: Grundlagenermittlung"])},
    "objects-PS-PreliminaryPlanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP2: Vorplanung"])},
    "objects-PS-DesignPlanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP3: Entwurfsplanung"])},
    "objects-PS-ApprovalPlanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP4: Genehmigungsplanung"])},
    "objects-PS-ImplementationPlanning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP5: Ausführungsplanung"])},
    "objects-PS-PreparationForAwardOfContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP6: Vorbereitung der Vergabe"])},
    "objects-PS-ParticipationInAwardingTheContract": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP7: Mitwirkung bei der Vergabe"])},
    "objects-PS-ObjectSupervisionConstructionSupervisionAndDocumentation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP8: Objektüberwachung –Bauüberwachung und Dokumentation"])},
    "objects-PS-ObjectSupervision": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LP9: Objektbetreuung"])},
    "objectGroups-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gruppen-Score"])},
    "objectGroups-show-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score anzeigen"])}
  },
  "score": {
    "fullRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie die Fragebögen verwalten"])},
    "createQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen erstellen"])},
    "questionnaires": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen"])},
    "questionnairesActive-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktuelle Versionen"])},
    "questionnairesArchived-tab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vorherige Versionen"])},
    "questionnaires-version": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Version ", _interpolate(_named("version"))])},
    "questionnaires-lastChanged": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Letzte Änderung wurde am ", _interpolate(_named("date")), " um ", _interpolate(_named("time")), " vorgenommen"])},
    "questionnaires-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktiv"])},
    "questionnaires-inactive": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Inaktiv"])},
    "questionnaires-live": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Live"])},
    "questionnaires-draft": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entwurf"])},
    "questionnaire-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neuen Fragebogen erstellen"])},
    "questionnaire-create-questionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen"])},
    "questionnaire-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen löschen"])},
    "questionnaire-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie den Fragebogen ", _interpolate(_named("title")), " wirklich löschen? "])},
    "questionnaire-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schritt kann nicht rückgängig gemacht werden."])},
    "questionnaire-save-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle speichern"])},
    "questionnaire-save-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie alle Änderungen im Fragebogen speichern?"])},
    "questionnaire-save-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schritt kann nicht rückgängig gemacht werden."])},
    "questionnaire-duplicate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen kopieren"])},
    "questionnaire-duplicate-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie den Fragebogen ", _interpolate(_named("title")), " wirklich kopieren? "])},
    "questionnaire-leave-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bearbeitung abbrechen"])},
    "questionnaire-leave-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihr Fragebogen enthält ungespeicherte Änderungen."])},
    "questionnaire-leave-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wollen Sie die Ansicht wirklich verlassen? Ihre Änderungen gehen dadurch verloren."])},
    "questionnaire-activate-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen veröffentlichen"])},
    "questionnaire-activate-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie den Fragebogen ", _interpolate(_named("title")), " wirklich veröffentlichen?"])},
    "questionnaire-activate-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschließend werden alle neu für ein Objekt auszufüllenden Fragebögen diese Version verwenden."])},
    "questionnaire-activate-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die bisherige veröffentlichte Version wird archiviert."])},
    "questionnaire-activate-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aktivieren"])},
    "questionnaire-locked-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen gesperrt"])},
    "questionnaire-locked-description": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Der Fragebogen ist zur Zeit durch ", _interpolate(_named("name")), " gesperrt. Der Fragebogen wird im Lesemodus geöffnet."])},
    "cluster": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster"])},
    "cluster-name-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUSTER I Governance (Fonds- / Unternehmensebene)"])},
    "cluster-name-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUSTER II Verbräuche & Emissionen (quantitativ)"])},
    "cluster-name-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUSTER III Asset Check (qualitativ)"])},
    "cluster-name-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLUSTER IV Taxonomie-Check"])},
    "cluster-empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Cluster enthält noch keine Einträge"])},
    "category": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie"])},
    "category-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie erstellen"])},
    "category-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Kategorie"])},
    "category-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kategorie löschen"])},
    "category-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Kategorie ", _interpolate(_named("category")), " wirklich löschen?"])},
    "category-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle untergeordneten Abschnitte und Fragen werden mit dieser Aktion ebenfalls gelöscht."])},
    "category-delete-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion ist temporär für Ihre Editiersitzung. Um den Löschvorgang zu persistieren, müssen Sie diesen Entwurf speichern."])},
    "section": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschnitt"])},
    "section-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschnitt erstellen"])},
    "section-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Abschnitts"])},
    "section-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschnitt löschen"])},
    "section-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie den Abschnitt ", _interpolate(_named("section")), " wirklich löschen?"])},
    "section-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle untergeordneten Fragen werden mit dieser Aktion ebenfalls gelöscht."])},
    "section-delete-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion ist temporär für Ihre Editiersitzung. Um den Löschvorgang zu persistieren, müssen Sie diesen Entwurf speichern."])},
    "question": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage"])},
    "question-next": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["weiter"])},
    "question-back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zurück"])},
    "question-initialTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Initialer Titel"])},
    "question-title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name der Frage"])},
    "question-create-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage erstellen"])},
    "question-basicQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Basisfrage"])},
    "question-explanation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erläuterung der Frage"])},
    "question-questionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage-Typ"])},
    "question-singleChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Single-Choice-Frage"])},
    "question-multipleChoice": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Multiple-Choice-Frage"])},
    "question-singleChoice-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können genau 1 Antwort auswählen."])},
    "question-multipleChoice-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie können mehrere Antworten auswählen."])},
    "question-valueQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wertfrage"])},
    "question-virtualQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtuelle Frage"])},
    "question-virtualQuestionType": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Virtueller Fragetyp"])},
    "question-virtualQuestionType-dw": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abweichung vom Durchschnittswert"])},
    "question-virtualQuestionType-dw-above": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), _interpolate(_named("unit")), " über dem DW"])},
    "question-virtualQuestionType-dw-between": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), _interpolate(_named("unit")), " des DW"])},
    "question-virtualQuestionType-dw-below": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), _interpolate(_named("unit")), " unter dem DW"])},
    "question-virtualQuestionType-dk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abweichung zum DK-Pfad"])},
    "question-virtualQuestionType-dk-deviation": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("value")), _interpolate(_named("unit")), " Abweichung zum DK-Pfad"])},
    "question-virtualQuestionType-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verlassen des DK-Pfades"])},
    "question-virtualQuestionType-year-departure": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Verlassen des DK-Pfades in ", _interpolate(_named("numberOfYears")), " Jahren (", _interpolate(_named("year")), ")"])},
    "question-virtualQuestionType-all-noStatement": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Keine Angabe"])},
    "question-virtualQuestionType-crremNotAvailable": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CRREM für Objekte für das Land aktuell nicht verfügbar"])},
    "question-averageValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittswert"])},
    "question-correspondingQuestion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zugehörige Frage"])},
    "question-isGreenhouseGas": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2-Emissionen"])},
    "question-option": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Option ", _interpolate(_named("index"))])},
    "question-addOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Option hinzufügen"])},
    "question-optionScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktzahl"])},
    "question-optionText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Text"])},
    "question-exclusiveOption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Exklusive Antwort"])},
    "question-taxonomyRelevant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxonomierelevant"])},
    "question-delete-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Frage löschen"])},
    "question-delete-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie die Frage ", _interpolate(_named("question")), " wirklich löschen? "])},
    "question-delete-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diese Aktion ist temporär für Ihre Editiersitzung. Um den Löschvorgang zu persistieren, müssen Sie diesen Entwurf speichern."])},
    "metadata-allClusters-header": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gesamtübersicht (Cluster ", _interpolate(_named("start")), " bis ", _interpolate(_named("end")), ")"])},
    "metadata-allClusters-clusterName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Clustername"])},
    "metadata-allClusters-maxScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximalscore Ist-Status"])},
    "metadata-allClusters-weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewichtung auf Gesamtebene"])},
    "metadata-allClusters-maxWeightedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkte max. gewichtet, je Cluster"])},
    "metadata-allClusters-result": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreichte gewichtete Punktzahl, je Cluster"])},
    "metadata-cluster-ownWeight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gewichtung: ", _interpolate(_named("weight")), "%"])},
    "metadata-cluster-scope": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bereich"])},
    "metadata-cluster-weight": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Gewichtung im Cluster ", _interpolate(_named("numeral"))])},
    "metadata-cluster-weightOverall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewichtung auf Gesamtebene"])},
    "metadata-cluster-maxScore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Punkte max. in Cluster ", _interpolate(_named("numeral"))])},
    "metadata-cluster-maxWeightedScoreOverall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkte max. gewichtet Gesamt"])},
    "metadata-cluster-reachedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreichte gewichtete Punktzahl"])},
    "metadata-cluster-reachedUnweightedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erreichte Punktzahl"])},
    "metadata-cluster-totals": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamt"])},
    "metadata-ecore-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Score"])},
    "metadata-taxonomy-compliance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxonomiekonformität"])},
    "metadata-taxonomy-compliant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Konform (", _interpolate(_named("taxonomyScore")), "%)"])},
    "metadata-taxonomy-nonCompliant": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nicht konform (", _interpolate(_named("taxonomyScore")), "%)"])},
    "metadata-chartHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punktzahl"])},
    "metadata-chartPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Punkte"])},
    "record-reachedScore": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Erreichte Punktzahl: ", _interpolate(_named("score"))])},
    "record-startAudit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Prüfung"])},
    "record-valueQuestionTextInput": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Antwort eingeben"])},
    "record-toAudit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen zur Prüfung freigeben"])},
    "record-toAudit-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie den Fragebogen für das Objekt ", _interpolate(_named("name")), " wirklich zur Prüfung freigeben?"])},
    "record-toAudit-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Antworten werden danach vorerst nicht mehr editierbar sein."])},
    "record-toAudit-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der zuständige Verifier wird Ihre Antworten kontrollieren."])},
    "record-toAudit-description4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wenn Sie dieses Einzelobjekt zur Prüfung eingereicht haben, können Sie die Objektgruppe, zu der dieses Objekt gehört, nicht mehr als Gruppe zur Prüfung einreichen."])},
    "group-toAudit-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppe zur Prüfung freigeben"])},
    "group-toAudit-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Sind Sie sicher, dass Sie die Objektgruppe ", _interpolate(_named("name")), " zur Prüfung einreichen wollen?"])},
    "group-toAudit-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mit Einreichung zur Prüfung können Sie die Fragebögen nicht mehr ändern – lediglich Anpassungen im Rahmen der Stichprobenprüfung werden möglich sein (Ihr ECORE Verifier wird Ihnen dies bei Bedarf mitteilen)."])},
    "group-toAudit-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die von Ihnen gemachten Angaben können bei Bedarf pro zu prüfendem Bogen maximal zwei Mal angepasst werden."])},
    "group-toAudit-checkbox": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hiermit bestätige ich, dass alle Objekte/ Assets, die zum Stichtag (31.12.2021) Teil dieser Objektgruppe waren, auch in dieser Objektgruppe erfasst und ein Fragenkatalog zu jedem Objekt/ Asset ausgefüllt wurde. \n\nMir ist bewusst, dass falsche oder fehlende Objekte/ Assets in der Objektgruppe zum Verlust des ECORE-Labels führen."])},
    "record-toEvaluation-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen zur Evaluierung freigeben"])},
    "record-toEvaluation-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie den Fragebogen für das Objekt wirklich zur Evaluation freigeben?"])},
    "record-toEvaluation-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Antworten werden danach vorerst nicht mehr editierbar sein."])},
    "record-toEvaluation-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der zuständige Evaluator wird Ihre Antworten kontrollieren."])},
    "record-toClosed-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen bestätigen"])},
    "record-toClosed-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie den Fragebogen für das Objekt wirklich bestätigen?"])},
    "record-toClosed-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Antworten werden danach nicht mehr editierbar sein."])},
    "record-toClosed-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dies ist der letzte Schritt im Prüfungsprozess."])},
    "record-toDeclined-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen ablehnen"])},
    "record-toDeclined-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie den Fragebogen für das Objekt wirklich ablehnen?"])},
    "record-toDeclined-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Antworten werden danach vorerst nicht mehr editierbar sein."])},
    "record-toDeclined-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der zuständige Bearbeiter wird Ihre Antworten kontrollieren."])},
    "record-toDeclined-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "record-toVerification-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen Nachweise einfordern"])},
    "record-toVerification-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie für die von Ihnen ausgewählten Fragen die Nachweise anfordern?"])},
    "record-toVerification-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bogen wird in der Zwischenzeit durch Sie nicht bearbeitbar sein."])},
    "record-toVerification-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Bogen ist wieder bearbeitbar, sobald der Kunde alle angeforderten Nachweise hochgeladen hat."])},
    "record-toVerification-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachweis anfordern"])},
    "record-decline-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ablehnen"])},
    "record-toAudit-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Prüfung"])},
    "record-toEvaluation-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Evaluierung"])},
    "record-toClosed-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abschließen"])},
    "record-saveAnswersForGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten für Objektgruppe speichern"])},
    "record-saveAnswersForGroup-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten für Objektgruppe speichern"])},
    "record-saveAnswersForGroup-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie wirklich alle Antworten aus Cluster I für Objekte der zugehörigen Gruppe ", _interpolate(_named("group")), " bereitstellen?"])},
    "record-saveAnswersForGroup-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anschließend hat der Objektdatenerfasser die Möglichkeit, diese Antworten für Objekte der Gruppe zu übernehmen."])},
    "record-saveAnswersForGroup-description3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Dies Betrifft nur die Antworten für den Fragebogen ", _interpolate(_named("questionnaire")), "."])},
    "record-saveAnswersForGroup-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Speichern"])},
    "record-takeAnswersFromGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten aus Objektgruppe übernehmen"])},
    "record-takeAnswersFromGroup-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Antworten aus Objektgruppe übernehmen"])},
    "record-takeAnswersFromGroup-description1": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Möchten Sie wirklich alle Antworten für Cluster I aus der zugehörigen Gruppe ", _interpolate(_named("group")), " übernehmen?"])},
    "record-takeAnswersFromGroup-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Falls Sie für dieses Objekt bereits Fragen zu Cluster I beantwortet haben, werden diese überschrieben. Dieser Schritt kann nicht rückgängig gemacht werden."])},
    "record-takeAnswersFromGroup-description3": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Die Antworten werden aus dem Fragebogen ", _interpolate(_named("questionnaire")), " genommen."])},
    "record-takeAnswersFromGroup-action": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Übernehmen"])},
    "review-accepted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angenommen"])},
    "review-declined": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abgelehnt"])},
    "review-inReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In Prüfung"])},
    "review-verification": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nachweis anfordern"])},
    "review-allComments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle Kommentare"])},
    "review-addComment": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kommentar hinzufügen"])},
    "review-timeStamp": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("date")), " um ", _interpolate(_named("time")), " Uhr"])},
    "entity-notFound": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möglicherweise haben Sie den Entwurf nicht gespeichert und das Browser-Fenster neugeladen.<br><br>Sie können oben über \"Hinzufügen\"<br> • eine neue Kategorie<br> • einen neuen Abschnit<br> • oder eine neue Frage zum Fragebogen hinzufügen."])},
    "export-dialog_headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sprache wählen"])},
    "export-dialog_description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie die Sprache für den Export"])},
    "export-dialog_description_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte wählen Sie das Dateiformat des Exports"])},
    "export-pdf-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PDF Vorschau"])},
    "export-pdf-unevaluatedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ungeprüfter Echtheitsscore"])},
    "export-pdf-docName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EcoreScore_ungeprüft"])},
    "questionnaire_show": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen anzeigen"])},
    "questionnaire-update-to-active-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen aktualisieren"])},
    "questionnaire-update-to-active-description1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie den Fragebogen wirklich aktualisieren?"])},
    "questionnaire-update-to-active-description2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragen könnten geändert, hinzugefügt oder gelöscht werden."])},
    "questionnaire-update-to-active-description3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dieser Schritt kann nicht rückgängig gemacht werden."])},
    "questionnaire-update-notification-objectadministrator": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist eine neue Version des Fragebogens verfügbar. Bitte aktualisieren Sie den Fragebogen."])},
    "questionnaire-update-notification-objectdatacollector": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Es ist eine neue Version des Fragebogens verfügbar. Bitte wenden Sie sich an den Objektadministrator, um diesen zu aktualisieren."])},
    "questionnaire-become-member": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Werden Sie ECORE-Mitglied, um den ungeprüften Echtzeitscore Ihres Objektes einsehen zu können."])},
    "questionnaire-update-notification-objectgroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Für diese Objektgruppe ist eine neue Version des Fragebogens verfügbar. Bitte aktualisieren Sie den Fragebogen."])},
    "question-changelog": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Änderunghistorie"])},
    "question-changelog-first-answered": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " hat die Frage ", _interpolate(_named("questionname")), " initial beantwortet am ", _interpolate(_named("date")), "."])},
    "question-changelog-last-modified": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " hat die Antwort der Frage ", _interpolate(_named("questionname")), " am ", _interpolate(_named("date")), " bearbeitet."])},
    "question-changelog-questionnaire-updated": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " hat den Fragebogen am ", _interpolate(_named("date")), " auf die Version ", _interpolate(_named("templatename")), " geupdatet."])},
    "question-changelog-removed": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("name")), " hat die Antwort der Frage ", _interpolate(_named("questionname")), " am ", _interpolate(_named("date")), " entfernt."])},
    "question-addfile": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anhänge"])},
    "question-addfile-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Über “Dateien hochladen” können Sie Anhänge hinzufügen. Die Dateien werden unmittelbar hochgeladen, Sie brauchen diese nicht durch “Speichern” bestätigen.\nZulässige Formate des Anhangs: PDF und JPG\nMaximale Größe des Anhangs: 10 MB"])},
    "question-addfile-button": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dateien hochladen"])},
    "question-select-score-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Score type"])},
    "question-select-score-type-option-Ecore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ecore"])},
    "question-select-score-type-option-Finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance"])},
    "submit-for-evaluation-group-modal-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen zur Evaluierung freigeben"])},
    "submit-for-evaluation-group-modal-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie den Fragebogen für Ihre Objektgruppe wirklich zur Evaluation freigeben? Ihre Review kann nicht mehr geändert werden. Der Zuständige Prüfer, wird Ihre Dokumente überprüfen."])},
    "submit-for-evaluation-group-modal-submit": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zur Evaluierung"])},
    "evaluate-group-accept-modal-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Überprüfung war erfolgreich"])},
    "evaluate-group-accept-modal-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten sie wirklich die Überprüfung des Fragebogens akzeptieren?"])},
    "evaluate-group-decline-modal-headline": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Prüfung ist leider fehlgeschlagen"])},
    "evaluate-group-decline-modal-text": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Möchten Sie wirklich die Überprüfung des Fragebogens ablehnen?"])},
    "cluster-identifier": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cluster-Bezeichnung"])},
    "cluster-identifier-with-number": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Cluster ", _interpolate(_named("number"))])},
    "finance-score": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finance-Score"])},
    "metadata-cluster-reachedWeightedFinanceScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewichtete Finanzpunktzahl erreicht"])},
    "metadata-cluster-maxWeightedFinanceScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Max. gewichteter Finanz-Score insgesamt"])},
    "metadata-allClusters-finance-weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gesamtgewicht finanzieren"])},
    "metadata-cluster-finance-weight": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzgewicht im Cluster"])},
    "metadata-cluster-finance-weightOverall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Finanzgewicht insgesamt"])},
    "metadata-cluster-maxFinanceWeightedScoreOverall": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["max. finanzgewichtete Punktzahl insgesamt"])},
    "metadata-allClusters-maxFinanceWeightedScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maximale finanzgewichtete Punktzahl pro Cluster"])},
    "questionnaire-type": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen-Version"])},
    "scoretype-ecore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE"])},
    "scoretype-finance": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["finanziell"])},
    "scoretype-stockAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand & Klimaschutz"])},
    "scoretype-stockAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bestand & Klimawandelanpassung"])},
    "scoretype-renovationAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanierung & Klimaschutz"])},
    "scoretype-renovationAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanierung & Klimawandelanpassung"])},
    "scoretype-newConstructionAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neubau & Klimaschutz"])},
    "scoretype-newConstructionAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neubau & Klimawandelanpassung"])},
    "scoretype-projectDevelopmentAndClimateProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektentwicklung & Klimaschutz"])},
    "scoretype-projectDevelopmentAndClimateChangeAdaption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektentwicklung & Klimawandelanpassung"])},
    "scoretype-newConstructionAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Neubau & Kreislaufwirtschaft"])},
    "scoretype-projectDevelopmentAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projektentwicklung & Kreislaufwirtschaft"])},
    "scoretype-renovationAndCircularEconomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanierung & Kreislaufwirtschaft"])},
    "question-relevantForReview": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bewertung relevant"])}
  },
  "home": {
    "welcome": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Herzlich Willkommen\nHier können Sie die wichtigsten Funktionen des ECORE Online-Tools kennenlernen & nutzen"])},
    "staticHeader": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Möglichkeiten bei der Nutzung des ECORE Online-Tools"])},
    "staticDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier finden Sie alle Zugänge, die Sie im Rahmen Ihrer Funktion nutzen können. Ihre Rolle können Sie oben rechts überprüfen."])},
    "createOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Organisation anlegen"])},
    "createOrganizationText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Kunden/Organisationen mit den dazugehörigen Unternehmensdaten angelegt und bearbeitet werden"])},
    "createUser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Benutzer anlegen"])},
    "createUserText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Mitarbeiter/externe Ansprechpartner mit den dazugehörigen Daten angelegt und bearbeitet werden"])},
    "createObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objekt anlegen"])},
    "createObjectText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können die Gebäudeeckdaten für ein Asset/Objekt, für das ein Fragebogen ausgefüllt werden soll, eingetragen und bearbeitet werden"])},
    "createObjectGroup": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppe anlegen"])},
    "createObjectGroupText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können bspw. Portfolios/Fonds angelegt werden, zu denen die Assets/Objekte, für die ein Fragebogen ausgefüllt wird, gehören"])},
    "answerQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen beantworten"])},
    "answerQuestionnaireText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kann der aktuelle Fragebogen für ein Asset/ Objekte ausgewählt und beantwortet werden"])},
    "editQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen bearbeiten"])},
    "editQuestionnaireText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier kann der aktuelle Fragebogen kopiert und editiert werden; neue Versionen können freigegeben werden"])},
    "reviewQuestionnaire": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogen prüfen"])},
    "reviewQuestionnaireText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können die eingereichten Fragebögen geprüft werden."])},
    "news-header1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE-News 22.04.2024"])},
    "news-text1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liebe ECORE-Mitglieder, ab heute ist der Fragebogen 2024_01 verfügbar! Bei einem Update von 2023_03 auf 2024_01 gehen keine Daten verloren!\n\nZudem ist die Assetklasse Wohnen jetzt für Projektentwicklungen verfügbar! Für Finance Partner ist nun auch der Finance-Score für Projektentwicklungen (Büro / Hotel / Logistik / Handel (high street) und Wohnen) verfügbar!\n\nDazu präsentieren wir auch das Feature Mixed-Use: komplexe Gebäude (mehrere Assetklassen) können nun in einem Bogen erfasst werden – einfach über die Gebäudeeckdaten den Split auswählen!\n\nLetze Neuerung in diesem Update: es sind neue Taxonomie-Kataloge verfügbar (Klimaziel Kreislaufwirtschaft für Sanierung und Projektentwicklung/ Neubau)!\n\nAlle neuen Kataloge finden Sie im Mitgliederbereich!"])},
    "news-header2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE-News 21.08.2023"])},
    "news-text2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liebe ECORE-Mitglieder, mit dem heutigen Tag stellen wir Ihnen das Update für die Ambitionslevel zur Verfügung!\n\nIm neuen Fragenkatalog 2023_03 finden Sie die Kennzeichnungen A1-A3 hinter der jeweiligen Nummerierung einer Frage (gilt für Bestand, Neubau & Sanierung - nicht für Projektentwicklungen).\n\nNur Fragen mit der Kennzeichnung A1 sind in 2023 relevant für die Berechnung des ECORE-Scores. Alle anderen Fragen sind jedoch weiterhin einseh- und bearbeitbar."])},
    "news-header3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE-News 07.08.2023"])},
    "news-text3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liebe ECORE-Mitglieder, mit dem heutigen Tag stellen wir Ihnen das Update für die Projektentwickler vor!\n\nFür die Assetklassen Büro/ Hotel/ Logistik & Handel (high street) können nun die Projektentwicklungs-Kataloge befüllt werden.\n\nZudem befinden sich sowohl für Bestand als auch für Projektentwicklungen die Taxonomiefragen mit dem Update in Cluster IV.\n\nObjekte, die bereits in ECORE angelegt sind, sind als „Bestand & Klimaschutz“ angelegt."])},
    "news-header4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE-News 07.06.2023"])},
    "news-text4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Liebe ECORE-Mitglieder,\n\ngerne stellen wir Ihnen mit dem heutigen Tag das Update für den neuen Bestandskatalog 2023 zur Verfügung!\n\nDank der tatkräftigen Unterstützung des FA Update konnten wir den Fragenkatalog weiter optimieren und vereinfachen.\nIm Mitgliederbereich finden Sie die Fragenkatalog mit Markup, um zu sehen, welche Fragen nach dem Update neu zu beantworten sind.\nDas Tool archiviert Ihren alten Bogen, sodass Sie diesen jederzeit noch einmal einsehen und bei Bedarf exportieren können.\n\nMit dem nächsten Update stellen wir Ihnen Cluster IV – Taxonomie-Check zur Verfügung!"])}
  },
  "certificate": {
    "ecoreLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Label"])},
    "ecoreLabelObject": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Label Objekt"])},
    "owner": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eigentümer"])},
    "ecoreScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Score"])},
    "ecoreScoreValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize([_interpolate(_named("score")), "%"])},
    "taxonomy": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxonomie konform"])},
    "taxonomyValue": (ctx) => {const { normalize: _normalize, interpolate: _interpolate, named: _named } = ctx;return _normalize(["Nicht konform (", _interpolate(_named("score")), "%)"])},
    "evaluationDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datum der Prüfung"])},
    "validityDate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gültig bis"])},
    "evaluatorName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer"])},
    "testerName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prüfer der Prüfstelle"])},
    "testerOrganization": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unternehmen"])},
    "signature": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Unterschrift"])},
    "footerText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lorem ipsum dolor sit amet, consectetur adipiscing elit. Etiam ullamcorper elementum elit, vitae vehicula urna bibendum ac. Duis placerat semper elit, quis vulputate leo posuere pharetra. "])},
    "createLabel": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Label erstellen"])}
  },
  "notifications": {
    "organisations-activate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisation wurde erfolgreich entsperrt."])},
    "organisations-activate-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisation konnte nicht entsperrt werden. Versuchen Sie es später erneut."])},
    "organisations-deactivate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisation wurde erfolgreich gesperrt."])},
    "organisations-deactivate-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisation konnte nicht gesperrt werden. Versuchen Sie es später erneut."])},
    "organisations-delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisation wurde erfolgreich gelöscht."])},
    "organisations-delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisation konnte nicht gelöscht werden. Versuchen Sie es später erneut."])},
    "organisations-create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisation wurde erfolgreich erstellt."])},
    "organisations-create-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisation konnte nicht erstellt werden. Versuchen Sie es später erneut."])},
    "organisations-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisation wurde erfolgreich aktualisiert."])},
    "organisations-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisation konnte nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "organisations-editContacts-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Ansprechpartner wurde erfolgreich aktualisiert."])},
    "organisations-editContacts-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Ansprechpartner konnte nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "organisations-list-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Organisationen konnten nicht geladen werden. Versuchen Sie es später erneut."])},
    "users-activate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account wurde erfolgreich entsperrt."])},
    "users-activate-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account konnte nicht entsperrt werden. Versuchen Sie es später erneut."])},
    "users-deactivate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account wurde erfolgreich gesperrt."])},
    "users-deactivate-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account konnte nicht gesperrt werden. Versuchen Sie es später erneut."])},
    "users-archive-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account wurde erfolgreich gelöscht."])},
    "users-archive-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account konnte nicht gelöscht werden. Versuchen Sie es später erneut."])},
    "users-create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account wurde erfolgreich erstellt."])},
    "users-create-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account konnte nicht erstellt werden. Versuchen Sie es später erneut."])},
    "users-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account wurde erfolgreich aktualisiert."])},
    "users-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Account konnte nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "users-list-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Accounts konnten nicht geladen werden. Versuchen Sie es später erneut."])},
    "questionnaire-create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen wurde erfolgreich erstellt."])},
    "questionnaire-create-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht erstellt werden. Versuchen Sie es später erneut."])},
    "questionnaire-create-onlyOnce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht erstellt werden. Nur ein Entwurf ist erlaubt."])},
    "questionnaire-clone-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen wurde erfolgreich dupliziert."])},
    "questionnaire-clone-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht dupliziert werden. Versuchen Sie es später erneut."])},
    "questionnaire-clone-onlyOnce": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht dupliziert werden. Nur ein Entwurf ist erlaubt."])},
    "questionnaire-delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen wurde erfolgreich gelöscht."])},
    "questionnaire-delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht gelöscht werden. Versuchen Sie es später erneut."])},
    "questionnaire-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht geladen werden. Versuchen Sie es später erneut."])},
    "questionnaire-score-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Score zum Fragebogen konnte nicht geladen werden. Versuchen Sie es später erneut. "])},
    "questionnaire-certificate-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Zertifikat zum Fragebogen konnte nicht geladen werden. Versuchen Sie es später erneut. "])},
    "questionnaire-list-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Fragebögen konnten nicht geladen werden. Versuchen Sie es später erneut."])},
    "questionnaire-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen wurde erfolgreich aktualisiert."])},
    "questionnaire-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "questionnaire-category-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kategorie wurde erfolgreich aktualisiert."])},
    "questionnaire-category-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kategorie konnte nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "questionnaire-category-delete-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kategorie wurde zum Löschen vorgemerkt. Die Änderung wird beim Speichern wirksam."])},
    "questionnaire-category-notFound-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Kategorie konnte nicht gefunden werden."])},
    "questionnaire-section-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Abschnitt wurde erfolgreich aktualisiert."])},
    "questionnaire-section-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Abschnitt konnte nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "questionnaire-section-delete-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Abschnitt wurde zum Löschen vorgemerkt. Die Änderung wird beim Speichern wirksam."])},
    "questionnaire-section-notFound-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Abschnitt konnte nicht gefunden werden."])},
    "questionnaire-question-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Frage wurde erfolgreich aktualisiert."])},
    "questionnaire-question-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Frage konnte nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "questionnaire-question-delete-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Frage wurde zum Löschen vorgemerkt. Die Änderung wird beim Speichern wirksam."])},
    "questionnaire-question-clone-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Frage wurde dupliziert. Die Änderung wird beim Speichern wirksam."])},
    "questionnaire-question-notFound-warning": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Frage konnte nicht gefunden werden."])},
    "questionnaire-question-answer-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Antwort wurde erfolgreich aktualisiert."])},
    "questionnaire-question-answer-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Frage konnte nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "questionnaire-question-answerAll-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Antworten wurden erfolgreich aktualisiert."])},
    "questionnaire-question-answerAll-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Antworten konnten nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "questionnaire-question-reviewSaveAll-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Beurteilungen wurden erfolgreich aktualisiert."])},
    "questionnaire-question-reviewSaveAll-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ihre Beurteilungen konnten nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "questionnaire-activate-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen wurde erfolgreich aktiviert."])},
    "questionnaire-activate-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht aktiviert werden. Versuchen Sie es später erneut."])},
    "questionnaire-activate-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte wegen fehlerhafter Eingaben nicht aktiviert werden."])},
    "questionnaire-start-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der neue Fragebogen wurde erfolgreich erstellt."])},
    "questionnaire-start-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ein neuer Fragebogen konnte nicht erstellt werden. Versuchen Sie es später erneut."])},
    "questionnaire-toAudit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen wurde erfolgreich zur Prüfung freigegeben."])},
    "questionnaire-toAudit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht zur Prüfung freigegeben werden. Versuchen Sie es später erneut."])},
    "questionnaire-toAudit-errorDocuments": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben nicht alle Fragen beantwortet."])},
    "questionnaire-toAudit-errorDocuments2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Antworten müssen verifiziert werden, haben aber kein Dokument."])},
    "questionnaire-change-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht zur Prüfung freigegeben werden. Versuchen Sie es später erneut."])},
    "questionnaire-change-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht zur Prüfung freigegeben werden. Versuchen Sie es später erneut."])},
    "questionnaire-change-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben nicht alle Fragen beantwortet."])},
    "questionnaire-change-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um den Fragebogen zur Evaluation einreichen zu können, müssen exakt 16 Fragen geprüft werden."])},
    "questionnaire-change-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um den Fragebogen zur Prüfung einreichen zu können, müssen Sie die Antworten aller abgelehnten Fragen aktualisieren."])},
    "questionnaire-change-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um den Fragebogen zur Nachweisanforderung übermitteln zu können, setzen Sie bitte den Status der Antworten im Fragebogen auf \"Dokumente hochladen\"."])},
    "questionnaire-change-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um einen Fragebogen abzulehnen, setzen Sie bitte den Status von mindestens einer Antwort im Fragebogen auf \"abgelehnt\"."])},
    "questionnaire-change-7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um den Fragebogen zur Evaluation senden zu können, muss jede geprüfte Antwort akzeptiert werden."])},
    "questionnaire-change-8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Um den Fragebogen zu schließen, müssen Sie die Antworten akzeptieren, bei denen der Verifier einen Nachweis verlangt hat."])},
    "questionnaire-change-9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Einige Antworten müssen verifiziert werden, haben aber kein Dokument."])},
    "questionnaire-change-10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht zur Prüfung freigegeben werden. Versuchen Sie es später erneut."])},
    "questionnaire-toEvaluation-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen wurde erfolgreich zur Evaluierung freigegeben."])},
    "questionnaire-toEvaluation-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht zur Evaluierung freigegeben werden. Versuchen Sie es später erneut."])},
    "questionnaire-toClosed-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen wurde erfolgreich bestätigt."])},
    "questionnaire-toClosed-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht bestätigt werden. Versuchen Sie es später erneut."])},
    "questionnaire-toDeclined-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen wurde erfolgreich abgelehnt."])},
    "questionnaire-toDeclined-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht zur abgelehnt werden. Versuchen Sie es später erneut."])},
    "questionnaire-toVerification-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen wurde erfolgreich zur Verifikation freigegeben."])},
    "questionnaire-toVerification-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht zur Verifikation freigegeben werden. Versuchen Sie es später erneut."])},
    "questionnaire-virtScoringOptions-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die verfügbaren Optionen virtueller Fragen konnten nicht geladen werden. Versuchen Sie es später erneut."])},
    "questionnaire-saveAnswersForGroup-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Antworten aus Cluster I wurden erfolgreich für die Objektgruppe gespeichert."])},
    "questionnaire-saveAnswersForGroup-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Antworten aus Cluster I konnten nicht für die Objektgruppe gespeichert werden. Versuchen Sie es später erneut."])},
    "questionnaire-takeAnswersFromGroup-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Antworten für Cluster I wurden erfolgreich aus der Objektgruppe übernommen."])},
    "questionnaire-takeAnswersFromGroup-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Antworten für Cluster I konnten nicht aus der Objektgruppe übernommen werden. Versuchen Sie es später erneut."])},
    "objects-delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Objekt wurde erfolgreich gelöscht."])},
    "objects-delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Objekt konnte nicht gelöscht werden. Versuchen Sie es später erneut."])},
    "objects-create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Objekt wurde erfolgreich erstellt."])},
    "objects-create-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Objekt konnte nicht erstellt werden. Versuchen Sie es später erneut."])},
    "objects-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Objekt wurde erfolgreich aktualisiert."])},
    "objects-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Objekt konnte nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "objects-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das Objekt konnte nicht geladen werden. Versuchen Sie es später erneut."])},
    "objects-list-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objekte konnten nicht geladen werden. Versuchen Sie es später erneut."])},
    "objects-assetClasses-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektklassen konnten nicht geladen werden. Versuchen Sie es später erneut."])},
    "objects-countries-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die verfügbaren Länder konnten nicht geladen werden. Versuchen Sie es später erneut."])},
    "objects-startQuestionnaire-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Fragebogen konnte nicht erstellt werden. Versuchen Sie es später erneut."])},
    "objectGroups-delete-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe wurde erfolgreich gelöscht."])},
    "objectGroups-delete-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe konnte nicht gelöscht werden. Versuchen Sie es später erneut."])},
    "objectGroups-create-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe wurde erfolgreich erstellt."])},
    "objectGroups-create-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe konnte nicht erstellt werden. Versuchen Sie es später erneut."])},
    "objectGroups-create-error-assettype-duplicate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jede Objektklasse kann nur einmal hinzugefügt werden."])},
    "objectGroups-create-error-percentages": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Prozentsätze müssen in der Summe 100 ergeben."])},
    "objectGroups-edit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe wurde erfolgreich aktualisiert."])},
    "objectGroups-edit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe konnte nicht aktualisiert werden. Versuchen Sie es später erneut."])},
    "objectGroups-list-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppen konnten nicht geladen werden. Versuchen Sie es später erneut."])},
    "objectGroups-toAudit-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe wurde erfolgreich zur Prüfung freigegeben."])},
    "objectGroups-toAudit-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe konnte nicht zur Prüfung freigegeben werden. Versuchen Sie es später erneut."])},
    "objectGroups-toAudit-error-not-same": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLU 1 wurde nicht in jedem Objekt der Objektgruppe gleich beantwortet. Bitte überprüfen Sie, dass CLU1 in jedem Objekt der Objektgruppe gleich beantwortet wurde."])},
    "objectGroups-change-0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe konnte nicht zur Prüfung freigegeben werden. Versuchen Sie es später erneut."])},
    "objectGroups-change-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sie haben keinen Zugriff auf diese Objektgruppe."])},
    "objectGroups-change-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mindestens ein Objekt in der Objektgruppe hat den falschen Status, um die Objektgruppe einzureichen."])},
    "objectGroups-change-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe konnte nicht zur Prüfung freigegeben werden. Versuchen Sie es später erneut."])},
    "objectGroups-change-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe konnte nicht geprüft werden. Versuchen Sie es später erneut."])},
    "objectGroups-change-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bitte füllen Sie das Feld \"Grundfläche in m²\" für jedes Objekt der Objektgruppe aus."])},
    "objectGroups-change-6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CLU 1 wurde nicht in jedem Objekt der Objektgruppe gleich beantwortet. Bitte überprüfen Sie, dass CLU 1 in jedem Objekt der Objektgruppe gleich beantwortet wurde."])},
    "tasks-assignTester-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Prüfer wurde erfolgreich zugewiesen."])},
    "tasks-assignTester-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Prüfer konnte nicht zugewiesen werden. Versuchen Sie es später erneut."])},
    "tasks-tester-fetch-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Prüfer konnten nicht geladen werden. Versuchen Sie es später erneut."])},
    "comments-post-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kommentar wurde erfolgreich veröffentlich."])},
    "comments-post-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Kommentar konnte nicht gespeichert werden. Versuchen Sie es später erneut."])},
    "questionnaire-update-to-active-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogenaktualisierung erfolgreich."])},
    "questionnaire-update-to-active-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogenaktualisierung fehlgeschlagen."])},
    "questionnaire-update-to-active-invalid": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alle aktiven Objekte der Gruppe müssen denselben Fragebogen haben, der nicht der aktive Fragebogen sein darf."])},
    "questionnaire-question-max-float-number-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der von Ihnen angegebene Wert übersteigt die maximal zulässige Menge. Bitte prüfen Sie den Wert noch einmal und geben Sie ihn erneut ein. Sollte das Problem weiterhin bestehen, wenden Sie sich bitte an support[at]ecore-rating.com"])},
    "fileUpload-post-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wurde erfolgreich hochgeladen."])},
    "fileUpload-post-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Hochladen der Datei ist ein Problem aufgetreten. Bitte versuche es erneut."])},
    "fileDelete-post-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Datei wurde erfolgreich gelöscht."])},
    "fileDelete-post-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beim Löschen der Datei ist ein Problem aufgetreten. Bitte versuche es erneut."])},
    "fileUpload-size-format-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Entweder ist die Datei zu groß oder das Format falsch. Bitte versuche es erneut."])},
    "submit-for-evaluation-group-modal-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe wurde erfolgreich zur Evaluation eingereicht."])},
    "submit-for-evaluation-group-modal-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Objektgruppe konnte nicht zur Evaluation eingereicht werden."])},
    "evaluate-group-modal-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Evaluation der Objektgruppe war erfolgreich."])},
    "evaluate-group-modal-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Evaluation der Objektgruppe ist fehlgeschlagen."])},
    "access-request-warning-access-already-granted": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff auf dieses Objekt wurde bereits genehmigt."])},
    "access-request-error-buyer-organisation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das angefragte Objekt gehört zu einer Käuferorganisation. Es ist nicht möglich, den Zugriff auf eine Käuferorganisation zu beantragen."])},
    "access-request-error-object-not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Das angefragte Objekt ist ungültig."])},
    "access-request-error": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Die Anfrage zum Zugriff auf dieses Objekt ist fehlgeschlagen."])},
    "access-request-success": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Der Zugriff auf dieses Objekt wurde erfolgreich angefragt."])}
  },
  "dashboard": {
    "assetdatasearch-name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Name des Gebaude"])},
    "assetdatasearch-survey-year": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erhebungsjahr"])},
    "general-info": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie die wichtigsten Statistiken kennenlernen"])},
    "asset-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben Asset"])},
    "show-data": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben zeigen"])}
  },
  "export": {
    "rights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie alle Objekte, Objektgruppen und Kundendaten exportieren"])},
    "export_format": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Format"])}
  },
  "analysisAndBenchmark": {
    "benchmarkTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dashboard"])},
    "benchmarkDescription": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hier können Sie die wichtigsten Statistiken kennenlernen"])},
    "fullRights": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wählen Sie hier ein Objekt oder eine Objektgruppe um das Dashboard zu öffnen"])},
    "objectGroupsTab": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Objektgruppen"])},
    "benchmarkButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zum Dashboard"])},
    "portfolioStructureInformation": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben Portfolio-Struktur"])},
    "ecorePortfolioScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Portfolio-Score"])},
    "esgPerformancePathway": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ESG-Performance-Pfad"])},
    "assetData": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Angaben Asset"])},
    "ecoreAssetScore": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ECORE Asset-Score"])},
    "taxonomyCheck": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxonomie-Check"])},
    "consumptionEmissionsStrandingPoints": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verbrauch - Emissionen - Stranding Points"])},
    "consumptionValues-assetValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset-Wert"])},
    "consumptionValues-strandingPoint": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Stranding Point"])},
    "consumptionValues-finalEnergyConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Endenergieverbrauch (kWh/m²/a)"])},
    "consumptionValues-co2Emission": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CO2-Emission (kg Co2/m²/a)"])},
    "consumptionValues-waterConsumption": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wasserverbrauch (m³/m²/a)"])},
    "consumptionValues-wasteProduction": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abfallaufkommen (kg/m²/a)"])},
    "assetDataValues-objectName": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gebäudename"])},
    "assetDataValues-streetAndNumber": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Straße und Hausnummer"])},
    "assetDataValues-zipAndCity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PLZ und Stadt"])},
    "assetDataValues-country": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Land"])},
    "assetDataValues-assetClass": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Asset Klasse"])},
    "assetDataValues-questionnaireVersion": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fragebogenversion"])},
    "assetDataValues-constructionAndRenovationYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr / Letzte Sanierung"])},
    "assetDataValues-featureQuality": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ausstattungsqualität"])},
    "assetDataValues-assetTotalArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttogeschossfläche"])},
    "assetDataValues-assetUseableArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsfläche"])},
    "assetDataValues-numberOfTenant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Anzahl der Mietparteien"])},
    "assetDataValues-historicalProtection": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denkmalschutz"])},
    "portfolioStructure-classDist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteilung Asset Klasse"])},
    "portfolioStructure-FeatureQualityDist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteilung Ausstattungsqualität"])},
    "portfolioStructure-CountryDist": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Verteilung Land"])},
    "portfolioStructure-avarageValue": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Durchschnittswerte"])},
    "totalArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bruttogeschossfläche"])},
    "constructionYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Baujahr"])},
    "renovationYear": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sanierung"])},
    "usableArea": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nutzungsfläche (NUF)"])},
    "EcorePortfolioScoreChartTitle": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gewichteter Portfolio-Score"])},
    "EcorePortfolioScoreButtonText": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Referenzwert"])},
    "taxonomyScore-compliant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Taxonomiekonform"])},
    "taxonomyScore-nonCompliant": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nicht taxonomiekonform"])}
  }
}